import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class PostedJobViewModel {
  @observable postStores: PostStore[] = []
  @observable loaded = false
  @observable loadingMore = false
  @observable loadMoreState = { page: 1, pageSize: 9 }
  @observable postFetching = false
  @observable query = {}
  @observable tabFilter = [
    { title: 'All post', query: 'all-post' },
    { title: 'Find My team', query: 'find_my_team' },
    { title: 'Find Job', query: 'find_job' },
    { title: 'Find Hobby Buddy', query: 'find_hobby_buddy' },
  ]

  @observable selectedTab = this.tabFilter[0] as any

  constructor() {
    //
  }

  @asyncAction *loadData(query: { tab?: string }) {
    this.query = query
    this.loaded = false
    this.setTab(query.tab)
    this.postStores = yield this.fetchPostsByFilter(query)
    this.loaded = true
  }

  @asyncAction *fetchPostsByFilter(query: { tab?: string }) {
    try {
      this.postFetching = true
      const targetQuery = {} as any
      if (['find_my_team', 'find_job', 'find_hobby_buddy'].includes(query.tab || '')) {
        targetQuery['recruitment_type'] = query.tab
      }
      const res = yield apiService.recruitmentHandler.fetchMyPostedJobs({ ...this.loadMoreState, ...targetQuery })
      return res?.data?.map((post) => new PostStore(post)) || []
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.postFetching = false
    }
  }

  @asyncAction *loadMore() {
    try {
      this.loadingMore = true
      this.loadMoreState.page += 1

      const newPostStores = yield this.fetchPostsByFilter(this.query)
      this.postStores = [...this.postStores, ...newPostStores]
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loadingMore = false
    }
  }

  @action setTab(query) {
    this.selectedTab = this.tabFilter.find((tab) => tab.query === query)
  }

  @action.bound onTabChange(tab: string) {
    const selectedTab = this.tabFilter.find((item) => item.title === tab)
    if (selectedTab) {
      this.selectedTab = selectedTab
      const currentQuery = appProvider.router.currentRoute.query
      appProvider.router.replace({ query: { ...currentQuery, tab: selectedTab.query } })
    }
  }

  @computed get tabs() {
    return this.tabFilter.map((tab) => tab.title)
  }
  @computed get tab() {
    return this.selectedTab?.title
  }
}
