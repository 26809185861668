















































































































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { PostedJobViewModel } from '@/modules/recruitment/daos/viewmodels/posted-job-viewmodel'
import { PostStore } from '@/stores/post-store'

@Observer
@Component({
  components: {
    'nothing-type-4': () => import('@/components/nothings/nothing-type-4.vue'),
  },
})
export default class extends Vue {
  @Provide() vm = new PostedJobViewModel()

  redirectDetailPage(postStore: PostStore) {
    const path = postStore.isFindMyTeam
      ? `/recruitment/posted-jobs/${postStore.post.id}`
      : `/recruitment/posts/${postStore.post.id}`
    if (this.$route.path !== path) {
      this.$router.push(path)
    }
  }

  @Watch('$route.query', { immediate: true }) queryChange(query: { tab: string }) {
    if (query) {
      this.vm.loadData(query)
    }
  }
}
